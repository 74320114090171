.image {
    margin-top: 25px;
    max-width: 90vw;
    max-height: 350px;
    text-align: center;
}

.panel {
    display: flex;
    flex-direction: column;
}

.panel>div {
    flex: 1;
    margin-right: 10px;
}

.panel>div:last-child {
    flex: 8;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header>div {
    flex: 8;
}

.header>div:last-child {
    flex: 1;
}

.stockInfo {
    display: flex;
    flex-direction: row;
}

.stockInfo:nth-child(even) {
    background-color: rgb(222, 252, 226);
}

.stockInfo>div {
    flex: 7;
    padding: 5px;
}

.stockInfo>div:last-child {
    flex: 1;
    text-align: right;
}

.header2 {
    font-weight: bold;
    background-color: #ddd;
}

.header1 {
    margin: 10px 0;
    font-size: 1.3rem;
    font-weight: bold;
}

@media (min-width: 500px) {
    .image {
        max-width: 350px;
    }
    .panel {
        flex-direction: row;
    }
    .header {
        flex-direction: row;
    }
}