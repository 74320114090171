.transferList {
    display: flex;
    flex-direction: row;
}

.left {
    flex: 5;
    margin-right: 5px;
    border: 2px solid rgb(241, 129, 55);
    border-radius: 8px;
}

.right {
    flex: 5;
    margin-left: 5px;
    border: 2px solid rgb(241, 129, 55);
    border-radius: 8px;
}

.title {
    flex: 1;
    margin: 10px 10px 0 10px;
    font-weight: bold;
    font-size: 1.2rem;
}