.screenSubTitle {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: .5rem;
}

@media (max-width: 500px) {
    .screenSubTitle {
        font-size: calc(.25rem + (100vw / 19));
    }
}