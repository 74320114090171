.saleInfo {
    padding: 10px 0;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
}

.saleInfo:nth-of-type(2n) {
    background-color: rgb(222, 252, 226);
  }

.saleInfo span:nth-child(1) {
    flex: 2;
    text-align: left;
}

.saleInfo span:nth-child(2) {
    flex: 3;
    text-align: left;
}

.saleInfo:hover {
    background-color: rgb(252, 219, 200);
}

.header .saleInfo:hover {
    background-color: #ddd;
}

.saleInfo span {
    flex: 1;
    text-align: right;
    padding: 5px;
}

.header {
    font-weight: bold;
    background-color: #ddd;
}