.input {
    margin-top: 1rem;
}

.label {
    text-align: left;
    font-size: .7rem;
    height: 1rem;
    margin-left: 10px;
}

.input input {
    border-width: 0 0 2px 0;
    border-color: rgb(41, 41, 141);
    font-size: .8rem;
    padding: 2px 10px;
    width: 100%;
}

.input input:focus {
    outline: transparent;
}

@media (min-width: 30rem) {
    .label {
        font-size: calc(.7rem + ((100vw - 30rem) / 70) * .3);
        height: calc(1rem + .7vw);
    }
    .input input {
        font-size: calc(.8rem + ((100vw - 30rem) / 70 * .7));
    }
}

@media (min-width: 100rem) {
    .label {
        font-size: 1rem;
        height: 1.5rem;
    }
    .input input {
        font-size: 1.5rem;
    }
}