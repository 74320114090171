.transferItem {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: 5px;
    border: 1px solid #ddd;
    font-size: 16pt;
}

.barcode {
    flex: 2;
}

.name {
    flex: 6;
}

.count {
    flex: 1;
    text-align: right;
    padding: 0 15px;
}

.header {
    background-color: #555;
    color: white;
    font-weight: bold;
}

@media print {
    .header {
        background-color: #555;
        color: white;
    }    
}