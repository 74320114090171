.signin {
    flex: 1;
    margin: 5vw;
}

.title {
    font-weight: 600;
    font-size: 1rem;
}

.subTitle {
    font-size: .8rem;
}

@media (min-width: 30rem) {
    .title {
        font-size: calc(1rem + ((100vw - 30rem) / 70));
    }
    .subTitle {
        font-size: calc(.8rem + ((100vw - 30rem) / 70) * .7);
    }
}

@media (min-width: 100rem) {
    .title {
        font-size: 2rem;
    }
    .subTitle {
        font-size: .1.5rem;
    }
}