.keyValue {
    margin: 10px 0;
    border-bottom: 1px solid rgb(223, 204, 204);
    display: flex;
    flex-direction: column;
}

.keyValue div {
    flex: 1;
}

.keyValue div:first-child {
    font-weight: bold;
}

.keyValue div:last-child {
    flex: 3;
}

@media (min-width: 500px) {
    .keyValue {
        flex-direction: row;
    }
}