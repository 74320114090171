.screenTitle {
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: .5rem;
}

@media (max-width: 500px) {
    .screenTitle {
        font-size: calc(.5rem + (100vw / 19));
    }
}