.item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.item div {
    flex: .5;
    padding: 5px;
}

.item div:nth-child(2) {
    flex: 1.5;
}

.item div:nth-child(3) {
    flex: 4;
}

.item div:nth-child(4) {
    flex: 1.5;
    text-align: right;
}

.item:nth-of-type(2n) {
    background-color: rgb(222, 252, 226);
}

.item:hover {
    background-color: rgb(252, 219, 200);
}

.item input {
    width: 100%;
}

.image {
    max-height: 40px;
}

.header {
    font-weight: bold;
    background-color: #ddd;
}

.header .item:hover {
    background-color: #ddd;
}

@media (max-width: 768px) {
    .item div:first-child {
        display: none;
    }
}