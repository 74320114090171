/** @format */

.card {
  background-color: rgb(25, 122, 126);
  color: white;
  padding: 10px;
  height: 8rem;
  display: inline-block;
  width: 16rem;
  text-align: center;
  border-radius: 2rem 0 2rem 0;
  margin: 10px;
  cursor: pointer;
  vertical-align: top;
}

.card:hover {
  background-color: rgb(91, 232, 236);
}

.title {
  font-size: 1.7rem;
  font-weight: bold;
}

.card a {
  color: white;
}

.card a:link,
.card a:visited,
.card a:active {
  text-decoration: none;
}

@media (max-width: 768px) {
  .card {
    width: calc(0.2rem + (100vw / 3));
    font-size: calc(0.1rem + (100vw / 50));
    height: calc(0.1rem + (100vw / 4));
  }

  .title {
    font-size: calc(0.5rem + (100vw / 37));
  }
}
