.transferListItem {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: 5px;
}

.transferListItem:first-child {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}

.transferListItem:last-child {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
}

.transferListItem:nth-child(odd) {
    background-color: rgb(250, 226, 210);
}

.description {
    flex: 6;
}

.count {
    flex: 1;
    text-align: right;
    padding: 0 15px;
}

.buttons {
    flex: 1;
    display: flex;
    cursor: pointer;
}

.buttons div {
    flex: 1;
    background-color: green;
    padding: 5px;
    margin-right: 3px;
    text-align: center;
    border-radius: 5px;
}
