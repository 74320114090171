.purchaseItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.purchaseItem:nth-of-type(2n) {
    background-color: rgb(222, 252, 226);
  }

.purchaseItem span:nth-child(1) {
    flex: 2;
    text-align: left;
}

.purchaseItem span:nth-child(2) {
    flex: 3;
    text-align: left;
}

.purchaseItem span {
    flex: 1;
    text-align: right;
    padding: 5px;
}

.header {
    font-weight: bold;
    background-color: #ddd;
}

.unknown {
    cursor: pointer;
    color: #888;
    font-style: italic;
}

.edit {
    cursor: pointer;
}

@media (max-width: 768px) {
    .purchaseItem span:nth-child(1) {
        display: none;
    }
}