.panel {
    display: flex;
    flex-direction: column;
}

.panel>div {
    flex: 1;
    margin-right: 10px;
}

.panel>div:last-child {
    flex: 8;
}
