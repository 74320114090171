.accountInfo {
    cursor: pointer;
    border-bottom: 1px solid #ddd;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.accountInfo:nth-of-type(2n) {
    background-color: rgb(222, 252, 226);
}

.accountInfo:nth-child(1) {
    flex: 2;
    text-align: left;
}

.accountInfo:hover {
    background-color: rgb(252, 219, 200);
}

.header .accountInfo:hover {
    background-color: #ddd;
}

.accountInfo span {
    padding: 5px;
}

.accountInfo>span:first-child {
    flex: 4;
    text-align: left;
}

.accountInfo>span:nth-child(2) {
    flex: 2;
    text-align: right;
}

.header {
    font-weight: bold;
    background-color: #ddd;
}

.prices {
    display: flex;
    flex-direction: row;
}

.prices span {
    flex: 1;
}

@media (max-width: 768px) {
    .prices {
        flex-direction: column;
    }
}