.saleItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.saleItem:nth-of-type(2n) {
    background-color: rgb(222, 252, 226);
  }

.saleItem span:nth-child(1) {
    flex: 2;
    text-align: left;
}

.saleItem span:nth-child(2) {
    flex: 3;
    text-align: left;
}

.saleItem span {
    flex: 1;
    text-align: right;
    padding: 5px;
}

.header {
    font-weight: bold;
    background-color: #ddd;
}

@media (max-width: 768px) {
    .saleItem span:nth-child(1) {
        display: none;
    }
}