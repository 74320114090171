.buttonBar {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;
}

.buttonBar button {
    font-size: 1rem;
    margin-left: 10px;
}

@media (min-width: 30rem) {
    .buttonBar button {
        font-size: calc(1rem + ((100vw - 30rem) / 140));
    }
}

@media (min-width: 100rem) {
    .buttonBar button {
        font-size: 1.5rem;
    }
}